.App {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.App-logo {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {

  }
}

.App-header {
  background-color: #2b2b2b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
header::before {
  height: 100vh;
  width: 110vw;
  background-image: url('bg-stars.svg');
  content: '';
  position: fixed;
  top: 0;
  z-index: 100;
}

.App-link {
  color: #ee5e1d;
  z-index: 200;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.responsive {
  width: 80%;
  height: auto;
}

@media only screen and (max-width: 900px) {
  .responsive {
    width: 100%;
  }
}